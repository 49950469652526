<template>

  <div
    v-cloak
    v-if="brandSlug === 'LAX'"
    class="header-wrapper header-wrapper-hello-msg"
    :style="set_style_variables"
    style="width: 100%;"
  >
    <div class="header-inner">
      <div class="menu-items-wrapper">
        <span class="hello-msg">We have upgraded our booking platform. Need Help? <a href="https://parking.flylax.com/lax-newbookingsite.html" target="_blank">Click here</a></span>
      </div>
    </div>
  </div>

  <div
    class="header-wrapper"
    @mouseleave="leaveUserPanel()"
    :class="`${manage_header_no_toggle ? ` manage-header-perm` : ``}`"
    :style="set_style_variables"
  >
    <div class="header-inner">
      <div
        @click="gotoPage(logo_link.url, logo_link.mtype)"
        class="ID-logo"
        :class="`ID-logo-${brandSlug}`"
      ></div>
      <div class="menu-items-wrapper">
        <div
          v-if="show_home && brandSlug !== 'SV1'"
          @click="gotoPage(home_link)"
          :class="`menu-item tab-home${
            highlight_tab_item === 1 || highlight_tab_item === '/'
              ? ' tab-active'
              : ''
          }`"
        >
          <i class="fa fa-home" aria-hidden="true"></i>
        </div>

        <template v-for="(item, index) in header_menu" :key="index">
          <div
            v-if="logged_in && item.loggedin && item.label"
            :class="`menu-item menu-item-loop tab-${index + 1}${
              item.label === highlight_tab_item ? ' tab-active' : ''
            }`"
            @click="gotoPage(item.url, item.mtype)"
          >
            {{ getrans(item.label) }}
          </div>
          <div
            v-if="
              !logged_in &&
              item.loggedout &&
              item.label &&
              item.label.toLowerCase() == 'login'
            "
            :class="`menu-item menu-item-loop tab-${index + 1}${
              item.label === highlight_tab_item ? ' tab-active' : ''
            }`"
          >
            {{ getrans(item.label) }}
          </div>
          <div
            v-if="
              !logged_in &&
              item.loggedout &&
              item.label &&
              item.label.toLowerCase() != 'login'
            "
            :class="`menu-item menu-item-loop tab-${index + 1}${
              item.label === highlight_tab_item ? ' tab-active' : ''
            }`"
            @click="gotoPage(item.url, item.mtype)"
          >
            {{ getrans(item.label) }}
          </div>
        </template>

        <div
          v-if="show_languages"
          @click="show_language_dropdown = !show_language_dropdown"
          class="menu-item menu-item-dropdown-lang tab-lang"
        >
          <span class="lang-text">{{ lang }}</span>
          <div class="lang-flag" :style="flagImage(brandSlug === 'PIT' ? 'us' : lang)"></div>

          <transition name="fade">
            <div
              v-show="show_language_dropdown"
              class="menu-item-dropdown-lang__select"
            >
              <div
                v-for="lng in languages"
                :key="lng"
                class="menu-item-dropdown-lang__select-item"
                @click="changeLang(lng)"
              >
                <div :class="`lang-flag ${lng}`" :style="flagImage(lng)"></div>
                <span>{{ lng.toUpperCase() }}</span>
              </div>
            </div>
          </transition>
        </div>

        <!-- @click="gotoPage('/yourdetails.html')" -->
        <div
          v-if="new_basket_number > 0 && basket_is_model_and_page"
          :class="basket_is_model_and_page"
          class="menu-item tab-basket basket_is_model_and_page_desktop"
          tabindex="0"
          @click="gotoPage('/yourdetails.html')"
        >
          <BasketIcon :basket_number="new_basket_number" />
          <span class="basket-text">Basket</span>
        </div>

        <div
          v-if="new_basket_number > 0 && basket_is_model_and_page"
          :class="basket_is_model_and_page"
          class="menu-item tab-basket basket_is_model_and_page_mobile"
          tabindex="0"
          @click="toggle_basket_model()"
        >
          <BasketIcon :basket_number="new_basket_number" />
          <span class="basket-text">Basket</span>
        </div>

        <div
          v-if="new_basket_number > 0 && !basket_is_model_and_page"
          class="menu-item tab-basket"
          tabindex="0"
          @click="
            basket_is_model
              ? toggle_basket_model()
              : gotoPage('/yourdetails.html')
          "
        >
          <BasketIcon :basket_number="new_basket_number" />
          <span class="basket-text">Basket</span>
        </div>

        <div
          v-if="show_user_is_logged_in"
          :class="`${manage_header ? `manage-header-show` : ``}`"
          class="user-panel"
        >
          <div class="user-initials">{{ initials }}</div>
          <span class="user-fullname">{{ firstname }} {{ surname }}</span>
          <span class="user-logout" @click="logoutsession"
            ><i class="fas fa-sign-out-alt"></i> {{ getrans("Logout") }}</span
          >
        </div>

        <div
          v-if="header_menu.length"
          class="menu-item tab-mobile-menu"
          @click="showMobileMenu = !showMobileMenu"
          @focusout="showMobileMenu = false"
          tabindex="0"
        >
          <i class="fa fa-bars"></i>
          <transition name="fade">
            <div v-show="showMobileMenu" class="menu-items_mobile-wrapper">
              <div
                :class="`menu-item-mobile tab-0`"
                @click="gotoPage('/index.html')"
              >
                <span>{{ getrans("Home") }}</span
                ><i class="fa fa-chevron-right"></i>
              </div>

              <template v-for="(item, index) in header_menu" :key="index">
                <div
                  v-if="logged_in && item.loggedin && item.label"
                  :class="`menu-item-mobile tab-${index + 1}`"
                  @click="gotoPage(item.url, item.mtype)"
                >
                  <span>{{ getrans(item.label) }}</span
                  ><i class="fa fa-chevron-right"></i>
                </div>

                <div
                  v-if="!logged_in && item.loggedout && item.label"
                  :class="`menu-item-mobile tab-${index + 1}`"
                  @click="gotoPage(item.url, item.mtype)"
                >
                  <span>{{ getrans(item.label) }}</span
                  ><i class="fa fa-chevron-right"></i>
                </div>
              </template>

              <template v-for="(item, index) in manage_menu" :key="index">
                <div
                  v-if="logged_in && item.label"
                  :class="`menu-item-mobile tab-${index + 1}-2`"
                  @click="gotoPage(item.url, item.mtype)"
                >
                  <span>{{ getrans(item.label) }}</span
                  ><i class="fa fa-chevron-right"></i>
                </div>
              </template>

              <div
                v-if="show_user_is_logged_in"
                @click="logoutsession"
                :class="`menu-item-mobile tab-logout`"
              >
                <span>{{ getrans("Logout") }}</span
                ><i class="fas fa-sign-out-alt"></i>
              </div>

              <div class="menu-item-mobile tab-lang" v-if="show_languages">
                <div v-for="lng in languages" :key="lng">
                  <div
                    :class="`lang-flag ${lng}`"
                    :style="flagImage(lng)"
                    @click="changeLang(lng)"
                  ></div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div
      v-if="logged_in"
      :class="`manage-header${manage_header ? ` manage-header-show` : ``}`"
    >
      <div class="manage-header-inner">
        <a
          v-for="(item, index) in manage_menu"
          :key="index"
          :href="item.url"
          :class="`${item.active === manage_active ? 'active' : ''}`"
          >{{ item.text }}</a
        >
        <a v-if="logged_in" @click="logged_in = !logged_in">{{
          getrans("Logout")
        }}</a>
      </div>
    </div>
  </div>
  <Dialog
    header="Please enter your email id to proceed"
    v-model:visible="dialog"
    :style="{ width: '50vw' }"
  >
    <div class="block">
      <label for="emailid">
        {{ "Enter your email address" }}
        <span class="form-mandatory">*</span>
      </label>
      <input
        name="emailid"
        autofocus="autofocus"
        type="text"
        id="emailid"
        data-lpignore="true"
      />
    </div>
    <template #footer>
      <button @click="closeBasic">{{ "Close" }}</button>
      <button @click="closeBasic" autofocus>{{ "Proceed" }}</button>
    </template>
  </Dialog>
</template>

<script>
import BasketIcon from "@/components/BasketIcon.vue";
import md5 from "js-md5";

const localforage = require("localforage");
let store = localforage.createInstance({
  name: "tranlations",
});

const { EncryptStorage } = require("encrypt-storage");
const encryptStorage = new EncryptStorage(process.env.VUE_APP_CHL, {
  storageType: "sessionStorage",
});

export default {
  name: "Header",
  props: {
    basket_is_model: undefined,
    basket_is_model_and_page: undefined,
    logo_link: undefined,
    home_link: undefined,
    show_home: undefined,
    show_languages: undefined,
    basket_number: undefined,
    manage_header_no_toggle: undefined,
    manage_active: undefined,
    userfirstname: undefined,
    logo_image: undefined,
    header_menu: undefined,
    show_basket: undefined,
    lang: undefined,
    translations: undefined,
    brandSlug: undefined,
    highlight_tab_item: undefined,
  },
  components: {
    BasketIcon,
  },
  emits: ["toggleBasket"],
  data() {
    return {
      show_user_is_logged_in: false,
      new_basket_number: 0,
      show_language_dropdown: false,
      logged_in: false,
      dialog: false,
      manage_header: false,
      showMobileMenu: false,
      showBasket: false,
      showBasketOnHeader: true,
      header_items_data: [
        { url: "/faqs.html", text: "FAQs", loggedin: true, loggedout: true },
        {
          url: "https://myaccount.rezcommdev.com",
          loggedin: true,
          loggedout: true,
          class: "",
          label: "Home",
          mcolumn: "No Columns",
          menusvg: "",
          mgroup: "",
          mid: 149,
          mtype: "Internal",
          position: "T",
        },
      ],
      manage_menu: [
        { url: "/bookings.html", text: "Your Bookings", active: "bookings" },
        { url: "/details.html", text: "Your Details", active: "details" },
        {
          url: "/changepassword.html",
          text: "Change Password",
          active: "changepassword",
        },
      ],
      languages: ["el", "en"],
      firstname: "",
      surname: "",
      initials: "",
      adhoc_cart: undefined,
    };
  },
  computed: {
    set_style_variables: function () {
      return {
        "--logo-image": `url(${this.logo_image})`,
      };
    },
  },
  methods: {
    getrans(string) {
      var s = md5(string);
      var ss = this.brandSlug + "-" + this.lang.toUpperCase() + "-" + s;
      console.log(ss);
      return this.translations[ss] || string;
    },
    changeLang(lang) {
      store.setItem("tranlations", null);
      this.setSessionData("language", lang);
      const url = new URL(window.location.href);
      var search_params = url.searchParams;
      search_params.set("lang", lang);
      url.search = search_params.toString();
      var new_url = url.toString();
      window.location = new_url;
    },
    flagImage(lang) {
      return `background-image: url(${require(`../assets/flags/` +
        lang.toUpperCase() +
        `.png`)});`;
    },

    setSessionData(name, value) {
      if (value != null) sessionStorage.setItem(name, JSON.stringify(value));
      else sessionStorage.removeItem(name);
    },

    openBasic() {
      this.dialog = true;
    },
    closeBasic() {
      this.dialog = false;
    },
    toggleBasket() {
      this.$emit("toggleBasket");
    },
    toggle_basket_model() {
      const new_event = new CustomEvent("basket_model_toggle", {
        detail: {
          data: "toggle",
        },
      });
      window.dispatchEvent(new_event);
    },
    gotoPage(url, type = null) {
      if (url.indexOf("myaccountdev.") > -1 && this.lang) {
        url += "?lang=" + this.lang;
      }
      if (url.indexOf("myaccount.") > -1 && this.lang) {
        url += "?lang=" + this.lang;
      }
      if (type && type.toLowerCase() === "external") {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
    enterUserPanel() {
      if (!this.manage_header_no_toggle) {
        this.manage_header = true;
      }
    },
    leaveUserPanel() {
      if (!this.manage_header_no_toggle) {
        this.manage_header = false;
      }
    },
    toggleUserPanel() {
      if (!this.manage_header_no_toggle) {
        this.manage_header = !this.manage_header;
      }
    },

    check_for_session() {
      //checks for session
      if (sessionStorage.getItem("session_uuid") === null) {
        //do nothing
        this.show_user_is_logged_in = false;
      } else {
        let userdata = encryptStorage.getItem("session_userdata");
        if (userdata && userdata !== "undefined") {
          this.show_user_is_logged_in = true;
          this.firstname = decodeURI(userdata.first_name);
          this.surname = decodeURI(userdata.last_name);
          let initial_1 = Array.from(this.firstname)[0];
          let initial_2 = Array.from(this.surname)[0];
          this.initials = initial_1 + initial_2;
          this.initials = this.initials.toUpperCase();
        }
      }
    },
    logoutsession() {
      sessionStorage.clear();
      window.location.reload();
    },
    setBasketNumber() {
      this.new_basket_number = 0;
      if (sessionStorage.getItem("cart")) {
        this.new_basket_number++;
      }
      if (sessionStorage.getItem("fasttrack_cart")) {
        this.new_basket_number++;
      }
      if (sessionStorage.getItem("lounge_cart")) {
        this.new_basket_number++;
      }
      if (sessionStorage.getItem("adhoc_cart")) {
        let adhoc_cart = encryptStorage.getItem("adhoc_cart");
        if (typeof adhoc_cart !== "undefined") {
          console.log("adhoc_cart_tt", adhoc_cart);
          for (let index = 0; index < adhoc_cart.length; index++) {
            if (Array.isArray(adhoc_cart[index])) {
              adhoc_cart[index].map((item) => {
                if (item.pr_price) {
                  this.new_basket_number++;
                }
              });
            } else {
              if (adhoc_cart[index] !== null) {
                if (adhoc_cart[index] && adhoc_cart[index].pr_price) {
                  this.new_basket_number++;
                }
              }
            }
          }
        }
      }
      if (sessionStorage.getItem("adhoc_lounge_cart")) {
        let adhoc_lounge_cart = encryptStorage.getItem("adhoc_lounge_cart");
        /* eslint-disable */
        for (const key in adhoc_lounge_cart) {
          if (adhoc_lounge_cart.hasOwnProperty(key)) {
            for (const item in adhoc_lounge_cart[key]) {
              this.new_basket_number++;
            }
          }
        }
        /* eslint-enable */
      }
    },
    set_langs() {
      if (this.brandSlug === "AIR") {
        this.languages = ["en", "el", "es", "de", "fr", "pt"];
        console.log(this.brandSlug, this.languages);
      }
      if (this.brandSlug === "SZG") {
        this.languages = ["de", "en"];
        //console.log(this.brandSlug, this.languages);
      }
      if (this.brandSlug === "FM1") {
        this.languages = ["de", "en"];
      }
      if (this.brandSlug === "FMM") {
        this.languages = ["de", "en"];
      }
      if (this.brandSlug === "BRE") {
        this.languages = ["de", "en"];
      }
      if (this.brandSlug === "PB1") {
        this.languages = ["de", "en"];
      }
      if (this.brandSlug === "PIT") {
        this.languages = ["us"];
      }
    },
  },
  mounted() {
    this.check_for_session();
    if (this.manage_header_no_toggle) {
      this.manage_header = true;
    }
    if (typeof this.userfirstname === "undefined") {
      this.logged_in = false;
    } else {
      this.logged_in = false;
    }

    this.setBasketNumber();
    this.set_langs();

    window.addEventListener(
      "update_basket",
      () => {
        this.setBasketNumber();
      },
      false
    );
  },
  updated() {
    this.set_langs();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.user-panel {
  display: none;
  @media screen and (min-width: 960px) {
    padding: 15px 15px;
    display: flex;
    align-items: center;
    max-height: 56px;
    transition: 0.15s ease-in-out;
    span {
      padding-left: 0.5rem;
      font-weight: bold;
    }
  }
  .user-fullname {
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .user-initials {
    background: var(--header_background_color_active, #000);
    color: var(--header_background_color, #fff);
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-logout {
    padding-left: 2rem;
    font-weight: bold;
    cursor: pointer;
    color: var(--header_basket_number_color, #236ca3);
  }
}

.user-panel.manage-header-show {
  background: $header-user-bg-hvr;
  color: $header-user-text-hvr;
}

.manage-header.manage-header-show {
  max-height: 60px;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
}

.manage-header-perm .manage-header {
  &.manage-header-show {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  }

  .manage-header-inner {
    a {
      align-items: center;
      display: flex;
      position: relative;
      height: 100%;
    }
    a.active:after {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.manage-header {
  display: none;
  @media screen and (min-width: 960px) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    transition: 0.15s ease-in-out;
    max-height: 0px;
    overflow: hidden;
    float: left;
    clear: both;
    margin-top: 0px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 60px;
    left: 0px;
    right: 0px;
    width: 100%;
    z-index: 99999;
    height: 60px;
    background: $header-manage-bg;
    color: $header-manage-text;
    .manage-header-inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 1280px;
      width: 100%;
      > * {
        padding: 0px 15px;
      }
      a {
        color: $header-manage-text;
        text-decoration-line: none;
      }
    }
  }
}

.header-wrapper {
  border-bottom: var(--header_border_bottom, $header-border-bottom);
  background: var(--header_background_color, $header-background);
  margin: 0;
  padding: 0;
  height: var(--header_height_desktop, 80px);
  @media screen and (max-width: 959px) {
    height: var(--header_height_mobile);
  }

  width: 100%;
  position: relative;
  box-sizing: content-box;
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: calc(1280px + 2rem);
    margin: 0 auto;
  }

  .ID-logo {
    background-repeat: no-repeat;
    background-size: contain;
    width: 33%;
    height: 100%;
    max-height: var(--logo_height_desktop);
    @media screen and (max-width: 959px) {
      max-height: var(--logo_height_mobile);
      width: 66%;
    }

    margin: 4px 1rem;
    box-sizing: border-box;
    position: relative;
    background-image: var(--logo-image);
    cursor: pointer;
  }
  .menu-items-wrapper {
    color: var(--header_font_color, $header-text-color);
    font-family: var(--header_font_family, $header-text-font);
    font-size: var(--header_font_size, 1rem);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    user-select: none;
    .menu-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0.5rem 1rem;
      border-radius: 2rem;
      // border-right: $header-btn-border;
      transition: 0.2s ease-in-out;
      margin-right: 0.5rem;

      &.tab-active {
        background: var(--header_background_color_active, #034a94);
        color: var(--header_font_color_active, #034a94);
      }

      &:hover {
        background: var(--header_background_color_hover, $header-btn-hvr-bg);
        color: var(--header_font_color_hover, #034a94);
      }
      // &:first-child {
      //   border-left: $header-btn-border;
      // }
      .fa-home {
        font-size: 20px;
      }
      .lang-text {
        border-radius: 20px;
        padding: 1px 10px;
        background: $header-lang-bg;
        color: $header-lang-text;
        font-family: $header-lang-font;
      }
      .lang-flag {
        width: 32px;
        height: 32px;
      }

      .basket-icon {
        width: 37px;
        height: 38px;
        margin-left: -5px;
      }
      .basket-text {
        display: none;

        // margin-left: 10px;
        // @media screen and (max-width: 1280px) {
        //   display: none;
        // }
      }
      .basket-number {
        position: absolute;
        left: 0px;
        width: 64px;
        text-align: center;
        top: 12px;
        font-weight: bold;
        font-size: 16px;
        color: $header-basket-number;
      }
    }
    .tab-basket {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0px;
    }
    .tab-mobile-menu {
      display: none;
    }

    .menu-items_mobile-wrapper {
      position: absolute;
      top: calc(var(--header_height_mobile) + 3px);
      right: 0px;
      display: flex;
      flex-direction: column;
      z-index: 99999;
      background: $header-mobile-bg;
      color: $header-mobile-text;
    }

    .menu-items_mobile-wrapper {
      width: 100%;
      max-width: 320px;
      box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.5);
      .menu-item-mobile {
        box-sizing: border-box;
        padding: 15px;
        text-align: left;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .menu-item-mobile.tab-lang {
        padding: 8px 15px;
        justify-content: flex-start;
        display: var(--header_show_langs, flex);
        .lang-flag {
          margin-right: 15px;
        }
      }
    }

    /* header compacting */
    @media screen and (max-width: 959px) {
      .menu-item-loop {
        display: none;
      }
      .tab-mobile-menu {
        padding: 1rem;
        display: flex;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.menu-items-wrapper .menu-item.tab-home {
  @media screen and (max-width: 959px) {
    display: none;
  }
}

.menu-items-wrapper .menu-item.tab-lang {
  display: var(--header_show_langs, flex);
  @media screen and (max-width: 959px) {
    display: none;
  }
}

.menu-item-dropdown-lang {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  max-height: 40px;

  &__select {
    background: #fff;
    position: absolute;
    top: calc(100% + 0.5rem);
    z-index: 99999;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0.5rem;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  }
  &__select-item {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
    color: var(--header_font_color, #384048);
  }
  &__select-item:hover {
    background: var(--header_background_color_hover, #eee);
    color: var(--header_font_color_hover, #384048);
  }
}

.header-wrapper .menu-items-wrapper {
  .upsell.basket_is_model_and_page_desktop {
    @media screen and (max-width: 1099.98px) {
      display: none;
    }
  }

  .upsell.basket_is_model_and_page_mobile {
    @media screen and (min-width: 1100px) {
      display: none;
    }
  }
}


.header-wrapper-hello-msg.header-wrapper-hello-msg {
  height: auto;
  background: #FFA500;

  .header-inner {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
    display: block;
  }

  .hello-msg {
    display: block;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    font-weight: bold;
    color: #fff;

    a {
      font-weight: bold;
      color: #fff;
      text-decoration: underline;
    }
  }
}


</style>
